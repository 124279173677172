import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import * as ReportTypes from '@app/shared/models/reportsModels';

export enum ReportActionTypes {
  GET = '[Report] GET',
  GET_SUCCESS = '[Report] GET_SUCCESS',
  GET_ERROR = '[Report] GET_ERROR',

  ADD_ONE = '[Report] ADD_ONE',
  ADD_ONE_SUCCESS = '[Report] ADD_ONE_SUCCESS',
  ADD_ONE_ERROR = '[Report] ADD_ONE_ERROR',

  GET_ONE = '[Report] GET_ONE',
  GET_ONE_SUCCESS = '[Report] GET_ONE_SUCCESS',
  GET_ONE_ERROR = '[Report] GET_ONE_ERROR',

  UPDATE = '[Report] UPDATE',
  UPDATE_SUCCESS = '[Report] UPDATE_SUCCESS',
  UPDATE_ERROR = '[Report] UPDATE_ERROR',

  DELETE = '[Report] DELETE',
  DELETE_SUCCESS = '[Report] DELETE_SUCCESS',
  DELETE_ERROR = '[Report] DELETE_ERROR',

  GENERATE_REPORT = '[Report] GENERATE_REPORT',
  GENERATE_REPORT_SUCCESS = '[Report] GENERATE_REPORT_SUCCESS',
  GENERATE_REPORT_ERROR = '[Report] GENERATE_REPORT_ERROR',

  CLEAR_GENERATED_REPORT = '[Report] CLEAR_GENERATED_REPORT'
}

// Actions for Getting  Reports
export class GetReports implements Action {
  readonly type = ReportActionTypes.GET;
  constructor(public payload: { companyId: string }) {}
}

export class GetReportsSuccess implements Action {
  readonly type = ReportActionTypes.GET_SUCCESS;
  // For Now API returns data in sub obj
  //   constructor(public payload: Report[]) {}
  constructor(public payload: { reports: ReportTypes.ReportHistoryList[] }) {}
}

export class GetReportsError implements Action {
  readonly type = ReportActionTypes.GET_ERROR;
  constructor(public payload: any) {}
}
// Action for Creating Report
export class CreateReport implements Action {
  readonly type = ReportActionTypes.ADD_ONE;

  constructor(public payload: { report: any }) {}
}
export class CreateReportSuccess implements Action {
  readonly type = ReportActionTypes.ADD_ONE_SUCCESS;

  constructor(public payload: { report: ReportTypes.ReportHistoryList }) {}
}
export class CreateReportError implements Action {
  readonly type = ReportActionTypes.ADD_ONE_ERROR;
  constructor(public payload: any) {}
}
export class GetReport implements Action {
  readonly type = ReportActionTypes.GET_ONE;
  constructor(public payload: { viewId: string }) {}
}
export class GetReportSuccess implements Action {
  readonly type = ReportActionTypes.GET_ONE_SUCCESS;

  constructor(public payload: { report: any }) {}
}
export class GetReportError implements Action {
  readonly type = ReportActionTypes.GET_ONE_ERROR;
  constructor(public payload: any) {}
}
export class UpdateReport implements Action {
  readonly type = ReportActionTypes.UPDATE;

  constructor(public payload: Update<ReportTypes.ReportHistoryList>) {}
}

export class UpdateReportSuccess implements Action {
  readonly type = ReportActionTypes.UPDATE_SUCCESS;

  constructor(public payload: { report: Update<ReportTypes.ReportHistoryList> }) {}
}

export class UpdateReportError implements Action {
  readonly type = ReportActionTypes.UPDATE_ERROR;

  constructor(public payload: any) {}
}

export class DeleteReport implements Action {
  readonly type = ReportActionTypes.DELETE;

  constructor(public payload: { report: ReportTypes.ReportHistoryList }) {}
}
export class DeleteReportSuccess implements Action {
  readonly type = ReportActionTypes.DELETE_SUCCESS;

  constructor(public payload: any) {}
}
export class DeleteReportError implements Action {
  readonly type = ReportActionTypes.DELETE_ERROR;

  constructor(public payload: ReportTypes.ReportHistoryList) {}
}

export class GenerateReport implements Action {
  readonly type = ReportActionTypes.GENERATE_REPORT;

  constructor(public payload: { propsForGen: ReportTypes.Request }) {}
}
export class GenerateReportSuccess implements Action {
  readonly type = ReportActionTypes.GENERATE_REPORT_SUCCESS;

  constructor(public payload: { report: ReportTypes.ReportTypes, type: string, propsForGen: ReportTypes.Request}) {}
}
export class GenerateReportError implements Action {
  readonly type = ReportActionTypes.GENERATE_REPORT_ERROR;

  constructor(public payload: any) {}
}

export class ClearGeneratedReport implements Action {
  readonly type = ReportActionTypes.CLEAR_GENERATED_REPORT;
}

export type ReportActions =
  | GetReports
  | GetReportsSuccess
  | GetReportsError

  | GetReport
  | GetReportSuccess
  | GetReportError

  | UpdateReport
  | UpdateReportSuccess
  | UpdateReportError

  | CreateReport
  | CreateReportSuccess
  | CreateReportError

  | DeleteReport
  | DeleteReportSuccess
  | DeleteReportError

  | GenerateReport
  | GenerateReportSuccess
  | GenerateReportError

  | ClearGeneratedReport;
