import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromFeature from '@app/reports/store/reducers';
import * as fromReport from '@app/reports/store/reducers/report.reducers';

// Reports State
export const selectReportState = createSelector(
  fromFeature.selectReportsState,
  (state: fromFeature.State) => state.reports
);

export const getReportsLoaded = createSelector(
  selectReportState,
  fromReport.getLoaded
);
export const getReportsLoading = createSelector(
  selectReportState,
  fromReport.getLoading
);

export const getGenerateReport = createSelector(
  selectReportState,
  fromReport.getGenerateReport
);
export const getGenerateReportType = createSelector(
  selectReportState,
  fromReport.getGenerateReportType
);
export const getPropsForGen = createSelector(
  selectReportState,
  fromReport.getPropsForGen
);

export const selectReportIds = createSelector(
  selectReportState,
  fromReport.selectReportIds
);
export const selectReportEntities = createSelector(
  selectReportState,
  fromReport.selectReportEntities
);
export const selectAllReports = createSelector(
  selectReportState,
  fromReport.selectAllReport
);

export const selectViewReport = createSelector(
  selectReportState,
  fromReport.getViewReport
);
