import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import {
  ReportActions,
  ReportActionTypes
} from '@app/reports/store/actions/report.actions';
import * as ReportTypes from '@app/shared/models/reportsModels';

export interface State extends EntityState<ReportTypes.ReportHistoryList> {
  selectId: string | null;
  loaded: boolean;
  loading: boolean;
  generateReport: ReportTypes.ReportTypes;
  generateReportType: string;
  propsForGen: ReportTypes.Request;
  viewReport: any;
}

export const adapter: EntityAdapter<ReportTypes.ReportHistoryList> = createEntityAdapter<ReportTypes.ReportHistoryList>({
  selectId: (report: ReportTypes.ReportHistoryList) => report.id
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  selectId: null,
  loaded: false,
  loading: false,
  generateReport: null,
  generateReportType: null,
  propsForGen: null,
  viewReport: null
});

export function reducer(state = initialState, action: ReportActions): State {
  switch (action.type) {
    case ReportActionTypes.GET_SUCCESS: {
      return adapter.setAll(action.payload.reports, {
        ...state,
        selectId: state.selectId,
        loaded: true,
        loading: false
      });
    }

    case ReportActionTypes.GET_ONE_SUCCESS: {
      return {
        ...state,
        loading: false,
        viewReport: action.payload.report
      };
    }

    case ReportActionTypes.ADD_ONE: {
      return {
        ...state,
        loading: true
      };
    }

    case ReportActionTypes.ADD_ONE_SUCCESS: {
      return adapter.addOne(action.payload.report, {
        ...state,
        loading: false,
        generateReport: null,
        generateReportType: null,
        propsForGen: null
      });
    }

    case ReportActionTypes.UPDATE: {
      return adapter.updateOne(action.payload, state);
    }

    case ReportActionTypes.UPDATE_SUCCESS: {
      return adapter.updateOne(action.payload.report, state);
    }

    case ReportActionTypes.DELETE: {
      return adapter.removeOne(action.payload.report.id, state);
    }

    case ReportActionTypes.GENERATE_REPORT: {
      return {
        ...state,
        loading: true
      };
    }

    case ReportActionTypes.GENERATE_REPORT_SUCCESS: {
      return {
        ...state,
        loading: false,
        generateReport: action.payload.report,
        generateReportType: action.payload.type,
        propsForGen: action.payload.propsForGen
      };
    }

    case ReportActionTypes.CLEAR_GENERATED_REPORT: {
      return {
        ...state,
        generateReport: null,
        generateReportType: null,
        propsForGen: null
      };
    }

    default: {
      return state;
    }
  }
}

export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const getSelectedId = (state: State) => state.selectId;
export const getGenerateReport = (state: State) => state.generateReport;
export const getGenerateReportType = (state: State) => state.generateReportType;
export const getPropsForGen = (state: State) => state.propsForGen;
export const getViewReport = (state: State) => state.viewReport;
export const {
  // select the array of Report ids
  selectIds: selectReportIds,
  // select the dictionary of Report entities
  selectEntities: selectReportEntities,
  // select the array of reports
  selectAll: selectAllReport,
  // select the total Report count
  selectTotal: selectReportTotal
} = adapter.getSelectors();
