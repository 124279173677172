import { Injectable } from '@angular/core';

import { Action } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Observable, of, map, switchMap, mergeMap, catchError } from 'rxjs';

import * as ReportActions from '@app/reports/store/actions/report.actions';
import { ReportService } from '@app/reports/services/report.service';
import { ToastHelper } from '@app/core/services/toast.service';

@Injectable()
export class ReportEffects {

  getReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ReportActions.GetReport>(ReportActions.ReportActionTypes.GET_ONE),
      switchMap((action: ReportActions.GetReport) => {
        return this._ReportService.get(action.payload.viewId).pipe(
          map(resp => {
            return new ReportActions.GetReportSuccess({
              report: resp
            });
          }),
          catchError(error => of(new ReportActions.GetReportError(error)))
        );
      })
    )
  });


  getReports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<ReportActions.GetReports>(ReportActions.ReportActionTypes.GET),
      switchMap((action: ReportActions.GetReports) => {
        return this._ReportService.getAll(action.payload.companyId).pipe(
          map(resp => {
            return new ReportActions.GetReportsSuccess({
              reports: resp || []
            });
          }),
          catchError(error => of(new ReportActions.GetReportsError(error)))
        );
      })
    )
  });


  createReport$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<ReportActions.CreateReport>(ReportActions.ReportActionTypes.ADD_ONE),
      mergeMap(action => {
        return this._ReportService.create(action.payload.report).pipe(
          map(resp => {
            this.notify.ok('Report Added Successful!');
            return new ReportActions.CreateReportSuccess({
              report: resp
            });
          }),
          catchError(error => {
            this.notify.error(error);
            return of(new ReportActions.CreateReportError(error));
          })
        );
      })
    )
  });


  updateReport$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<ReportActions.UpdateReport>(ReportActions.ReportActionTypes.UPDATE),
      mergeMap(action => {
        return this._ReportService.update(action.payload.changes).pipe(
          map(resp => {
            this.notify.ok('Report update was Successful!');
            return new ReportActions.UpdateReportSuccess({
              report: { id: resp.data.id, changes: resp.data }
            });
          }),
          catchError(error => {
            this.notify.error(error);
            return of(new ReportActions.UpdateReportError(action.payload.changes));
          })
        );
      })
    )
  });


  deleteReport$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<ReportActions.DeleteReport>(ReportActions.ReportActionTypes.DELETE),
      mergeMap(action => {
        return this._ReportService.delete(action.payload.report).pipe(
          map((data: Response) => {
            this.notify.ok('Report Deleted Successful!');
            return new ReportActions.DeleteReportSuccess(data);
          }),
          catchError(error => {
            this.notify.error(error);
            return of(new ReportActions.DeleteReportError(error));
          })
        );
      })
    )
  });


  generateReport$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType<ReportActions.GenerateReport>(ReportActions.ReportActionTypes.GENERATE_REPORT),
      mergeMap(action => {
        return this._ReportService.generateReport(action.payload.propsForGen).pipe(
          map((data) => {
            return new ReportActions.GenerateReportSuccess(
              {
                report: data,
                type: action.payload.propsForGen.report_type,
                propsForGen: action.payload.propsForGen
              }
            );
          }),
          catchError(error => {
            this.notify.error(error);
            return of(new ReportActions.GenerateReportError(error));
          })
        );
      })
    )
  });

  constructor(
    private actions$: Actions,
    private _ReportService: ReportService,
    private notify: ToastHelper
  ) { }
}
