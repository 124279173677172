import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/services/api.service';
import * as ReportTypes from '@app/shared/models/reportsModels';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  path = `api/reports`;
  pathHistory = `api/reports-history`;
  constructor(private apiService: ApiService) { }

  get(viewId: string) {
    return this.apiService.get(this.pathHistory, {
      action_type: 'template',
      view_id: viewId
    });
  }

  getAll(companyId: string) {
    return this.apiService.get(this.pathHistory, { company_id: companyId });
  }

  create(report) {
    return this.apiService.post(this.pathHistory, report);
  }

  update(data) {
    data = this.cleanObj(data);
    return this.apiService.put(`${this.path}/${data.id}`, data);
  }

  delete(report) {
    delete report.content;
    delete report.data;
    return this.apiService.delete(this.pathHistory, report);
  }

  upload(data) {
    return this.apiService.postForm(`${this.path}`, data);
  }

  download(data) {
    data = this.cleanObj(data);
    return this.apiService.getFile(`${this.path}/download`, data);
  }

  cleanObj(data) {
    Object.keys(data)
      .filter(k => k !== '_id' && k.startsWith('_'))
      .map(k => {
        console.log('delete', k);
        delete data[k];
      });
    return data;
  }

  getBlobURL(payload) {
    const blob = new Blob([payload.body], {
      type: payload.headers.get('content-type')
    });
    // Remove Report Ext
    const name = payload.name.replace(/\.[^/.]+$/, '');
    const ext = payload.uploadName.split('.').pop();
    const url = window.URL.createObjectURL(blob);
    return { _url: url, name: name + (ext ? `.${ext}` : '') };
  }

  generateReport(genProps: ReportTypes.Request) {
    // genProps.trucks = genProps.trucks.join(', ');
    return this.apiService.get(this.path, {
      ...genProps, ...{ trucks: genProps.trucks.join(', ') }
    });
  }
}
