import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromReports from '@app/reports/store/reducers/report.reducers';

export interface State {
  reports: fromReports.State;
}

export const reducers: ActionReducerMap<State> = {
  reports: fromReports.reducer
};

export const selectReportsState = createFeatureSelector<State>('reports');
